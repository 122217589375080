var apiEndpoint = 'https://api.timerise.io/v1';
var googleMapsUrl = 'https://www.google.com/maps/search/?api=1&query=';

function setCookie(name, value, days) {
  var expires = '';
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days*24*60*60*1000));
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '')  + expires + '; path=/';
}
function getCookie(name) {
  var nameEQ = name + '=';
  var ca = document.cookie.split(';');
  for (var i=0;i < ca.length;i++) {
    var c = ca[i];
    while (c.charAt(0)==' ') c = c.substring(1,c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}
function shuffleArray(array) {
  let currentIndex = array.length,  randomIndex;
  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }
  return array;
}
async function getOrganization(organizationId) {
  var query = JSON.stringify({ query:`{ organization(organizationId:"${organizationId}") { organizationId title localTimeZone } }` });
  try {
    var result = await $.ajax({ url: apiEndpoint, contentType: 'application/json', type: 'POST', data: query });
    return result.data?.organization || {};
  } catch {
    return {};
  }
}
async function getProject(projectId) {
  var query = JSON.stringify({ query:`{ project(projectId:"${projectId}") { projectId defaultLocale localTimeZone title logoUrl } }` });
  try {
    var result = await $.ajax({ url: apiEndpoint, contentType: 'application/json', type: 'POST', data: query });
    return result.data?.project || {};
  } catch {
    return {};
  }
}
async function getProjectsByOrganizationId(organizationId) {
  var query = JSON.stringify({ query:`{ projects(organizationId:"${organizationId}") { projectId title } }` });
  try {
    var result = await $.ajax({ url: apiEndpoint, contentType: 'application/json', type: 'POST', data: query });
    return result.data?.projects || [];
  } catch {
    return {};
  }
}
async function getServicesByOrganizationId(organizationId) {
  var query = JSON.stringify({ query:`{ services(organizationId:"${organizationId}" featured:true limit:72) { project { title } locations { title address } serviceId featured title shortDescription durationInfo price shortUrl media { url } draft } }` });
  try {
    var result = await $.ajax({ url: apiEndpoint, contentType: 'application/json', type: 'POST', data: query });
    return result.data?.services || [];
  } catch {
    return [];
  }
}
async function getServicesByProjectId(projectId) {
  var query = JSON.stringify({ query:`{ services(projectId:"${projectId}" limit:72) { project { title } locations { title address } serviceId featured title shortDescription durationInfo price shortUrl media { url } draft } }` });
  try {
    var result = await $.ajax({ url: apiEndpoint, contentType: 'application/json', type: 'POST', data: query });
    return result.data?.services || [];
  } catch {
    return [];
  }
}
async function searchServices(q, organizationId, projectId) {
  var query = JSON.stringify({ query:`{ services(organizationId:"${organizationId}", query:"${q}") { project { title } locations { title address } serviceId featured title shortDescription durationInfo price shortUrl media { url } draft } }` });
  try {
    var result = await $.ajax({ url: apiEndpoint, contentType: 'application/json', type: 'POST', data: query });
    return result.data?.services || [];
  } catch {
    return [];
  }
}
function renderLogo(projectTitle, projectLogoUrl) {
  if(projectLogoUrl) {
    $('#logo img').attr('src', projectLogoUrl);
    $('#logo img').attr('title', projectTitle);
    $('#logo img').attr('alt', projectTitle);
  } else {
    $('#logo img').hide();
    $('#logo').text(projectTitle);
  }
}
function renderFooter(title, localTimeZone) {
  $('#footerTitle').text(title);
  $('#footerLocalTimeZone').text(localTimeZone);
}
function renderCitySelect(cities) {
  if(cities.length > 0) {
    $('#citySelect').empty();
    $('#citySelect').append($('<option>', { value: 'All', text : 'All' }));
    $.each(cities, function (i, city) {
      if(city && city != '') $('#citySelect').append($('<option>', { value: city, text : city }));
    });
    $('#citySelectWrapper').show();
  } else {
    $('#citySelectWrapper').hide();
  }
}
function renderSearchInput() {
  $('#searchInputWrapper').show();
}
function renderClinicSelect(clinics) {
  // clinics = clinics.sort((a, b) => {
  //   const nameA = a.title.toUpperCase();
  //   const nameB = b.title.toUpperCase();
  //   if (nameA < nameB) return -1;
  //   if (nameA > nameB) return 1;
  //   return 0;
  // });
  if(clinics.length > 0) {
    $('#clinicSelect').empty();
    $('#clinicSelect').append($('<option>', { value: 'all', text : 'All' }));
    $.each(clinics, function (i, clinic) {
      $('#clinicSelect').append($('<option>', { value: clinic.id, text : clinic.title }));
    });
    $('#clinicSelectWrapper').show();
  } else {
    $('#clinicSelectWrapper').hide();
  }
}
function renderLabel(label) {
  var content = '';
  if(label) content = '<span class="label">Featured</span>';
  return content;
}
function renderCover(coverUrl) {
  var content = '<div class="cover"><img src="' + coverUrl + '" style="aspect-ratio: 4 / 3; object-fit: cover;"></div>';
  return content;
}
function renderButton(buttonUrl, buttonText, serviceId) {
  var content = '<a class="button timerise-button" href="' + buttonUrl + '" data-service-id="' + serviceId + '">' + buttonText + '</a>';
  return content;
}
function renderTitle(title) {
  var content = '<h2 class="title">' + title + '</h2>';
  return content;
}
function renderDescription(description) {
  var content = '';
  if(description) content = '<div class="description"><p>' + description + '</p></div>';
  return content;
}
function renderInfo(name, address, duration, price) {
  var content = '<ul class="info">';
  content += '<li class="name">' + name + '</li>';
  if(address) content += '<li class="address"><a href="' + googleMapsUrl + encodeURIComponent(address) + '" target="_blank">' + address + '</a></li>';
  if(duration) content += '<li class="duration">' + duration + '</li>';
  if(price > 0) content += '<li class="price">' + price + ' PLN</li>';
  content += '</ul>';
  return content;
}
function renderServices(services) {
  $('#services').empty();
  if(services.length > 0) {
    services.forEach(function(service) {
      if(service.draft !== true) {
        var label = renderLabel(service.featured);
        var cover = renderCover(service.media[0]?.url || 'https://cdn.timerise.io/app/placeholder-light.png');
        var title = renderTitle(service.title);
        var description = renderDescription(service.shortDescription);
        var info = renderInfo(service.project.title, service.locations[0]?.title, service.durationInfo, service?.price || 0);
        var button = renderButton(service.shortUrl, 'Book now', service.serviceId);
        var box = '<div class="service-box noselect">' + label + '<div class="service-box-top">' + cover + title + description + '</div><div class="service-box-bottom">' + info + button + '</div></div>';
        $('#services').append(box);
      }
    });
    if (typeof bindTimeriseActions === 'function') { 
      bindTimeriseActions();
    }
    $('#emptyServices').hide();
    $('#services').show();
    $('#loading').hide();
    $('#searching').hide();
    $('#footer').show();
  } else {
    renderEmptyServices();
  }
}
function renderEmptyServices() {
  $('#loading').hide();
  $('#searching').hide();
  $('#services').hide();
  $('#footer').hide();
  $('#emptyServices').show();
}

$(function() {

  if(getCookie('cookiesAccept') !== 'Y') {
    $('#cookiesBarOverlay').show();
    $('#cookiesBar').show();
  }
  $('#cookiesBar a.close').on('click', function(e) {
    e.preventDefault();
    setCookie('cookiesAccept', 'Y', 30);
    $('#cookiesBar').hide();
    $('#cookiesBarOverlay').hide();
  });

  var organizationId = 'XFV6dCD8YZM3IeOiOz3z';

  getOrganization(organizationId).then((organization) => {
    renderFooter(organization.title, organization.localTimeZone);
    getProjectsByOrganizationId(organizationId).then((projects) => {
      var clinics = [];
      projects.forEach(function(project) {
        if(project.projectId && project.projectId !== 'qu29D7sr9SrNBEcdtRo0') clinics.push({ id: project.projectId, title: project.title });
      });
      if(clinics.length > 0) renderClinicSelect(clinics);
    });
    getServicesByOrganizationId(organizationId).then((services) => {
      // var cities = [];
      // services.forEach(function(service) {
      //   cities.push(service.locations[0]?.address);
      // });
      // cities = cities.filter((v, i, a) => a.indexOf(v) === i);
      // renderCitySelect(cities);
      renderServices(shuffleArray(services));
    });
    renderSearchInput();
  });

  $('#searchInput').keyup(function() {
    var q = $('#searchInput').val();
    if (q.length > 2) {
      $('#clinicSelect').val('all');
      $('#footer').hide();
      $('#services').hide();
      $('#emptyServices').hide();
      $('#searching').show();
      searchServices(q, organizationId).then((services) => {
        renderServices(services);
      });
    } else if (q.length < 2) {
      getServicesByOrganizationId(organizationId).then((services) => {
        renderServices(services);
      });
    }
  }).keydown(function(event) {
    if (event.which == 13) {
      event.preventDefault();
    }
  });

  $('#clinicSelect').change(function() {
    $('#searchInput').val('');
    $('#footer').hide();
    $('#services').hide();
    $('#emptyServices').hide();
    $('#loading').show();
    $('#services').empty();
    var projectId = $('#clinicSelect').val() || 'all';
    if(projectId === 'all') {
      getServicesByOrganizationId(organizationId).then((services) => {
        renderServices(services);
      });
    } else {
      getServicesByProjectId(projectId).then((services) => {
        renderServices(services);
      });
    }
  });

  firebase.analytics();
});